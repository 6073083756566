import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { getUserDetails } from "../../action/user";
import backBtn from "../../assets/images/chevron-left.svg";
import { currencySymbol } from "../../helper/constants";

export const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentUrl, setUrl] = useState(false);

  const fetchDetails = async () => {
    const response = await getUserDetails(id);
    if (response.status) {
      setData(response.data[0]);
    }
  };
  useEffect(() => {
    const url = window.location.href;
    setUrl(url.includes("consumer"));
    fetchDetails();
  }, []);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left consumer_ttl">
            <img
              className="i_button"
              src={backBtn}
              alt="back"
              onClick={() => {
                // currentUrl ? navigate("/consumers") : navigate("/contractors");
                navigate(-1);
              }}
            />

            <div className="top_heading">
              {currentUrl ? "Consumer " : "Contractor "}
              Details
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box"></div>
      <div className="payout_data-content mb-4">
        <div className="row mb-2">
          <div className="col-lg-3">
            <div className="data">
              Name: <span>{data?.name}</span>
            </div>

            <div className="data">
              Address: <span>{data?.addressDetails?.address}</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="data">
              Email: <span>{data?.email}</span>
            </div>
            <div className="data">
              Location: <span>{data?.addressDetails?.location}</span>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="data">
              Mobile: <span>{data?.mobile}</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="data">
              <span>
                {data?.pic ? (
                  <img
                    src={process.env.REACT_APP_S3_URL + "/" + data?.pic}
                    alt="Profile"
                    width={100}
                    height={100}
                  />
                ) : (
                  "No Image found"
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      {data?.business ? (
        <>
          <div className="tab_box">
            <div className="section-heading">Business Details</div>
            <div className="payout_data-content mb-4">
              <div className="row mb-2">
                <div className="col-lg-3">
                  <div className="data">
                    Business Name: <span>{data?.business?.name}</span>
                  </div>
                  <div className="data">
                    Completed Projects:
                    <span>{data?.business?.completedproject}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="data">
                    Business Registered Number:
                    <span>{data?.business?.registrationNumber}</span>
                  </div>
                  <div className="data">
                    Visiting Charges:
                    <span>{currencySymbol}{" "}{data?.business?.visitingCharges}</span>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="data">
                    TIN Number: <span>{data?.business?.registrationTIN}</span>
                  </div>
                  <div className="data">
                    Services:{" "}
                    <div className="flex-content">
                      {data?.business?.services?.map((value, index) => (
                        <span key={index}>
                          {value?.name}
                          {index < data.business.services.length - 1 && (
                            <>,&nbsp;</>
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="data">
                    Experience: <span>{data?.business?.expereince}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab_box">
            <div className="section-heading">Team Details</div>
            <div className="payout_data-content mb-4">
              <div className="row mb-2">
                <div className="col-lg-3">
                  <div className="data">
                    Total Workers:{" "}
                    <span>{data?.business?.teamDetails?.totalPersons}</span>
                  </div>
                  <div className="data">
                    Labors: <span>{data?.business?.teamDetails?.labors}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="data">
                    Skilled Workers:{" "}
                    <span>{data?.business?.teamDetails?.skilledWorkers}</span>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="data">
                    Semi-Skilled Workers:{" "}
                    <span>
                      {data?.business?.teamDetails?.semiSkilledWorkers}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="data">
                    Porters: <span>{data?.business?.teamDetails?.porters}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
