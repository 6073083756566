import React, { useState, useEffect } from "react";
import trash from "../../assets/images/trash-03.svg";
import backBtn from "../../assets/images/chevron-left.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Milestones from "./milestone";
import { createProject, fetchRequestDetails } from "../../action/request";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import { uploadMediaImage } from "../../action/user";
import { handleEnterKey, handleKeyDown } from "../../helper/helper";
import FileUpload from "../../components/uploadFile/fileUpload";
import { currencySymbol } from "../../helper/constants";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AddProjectPreview from "./addProjectPreview";

export const ProjectAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const imagesTypes = ["JPG", "PNG", "JPEG"];
  const docTypes = ["PDF", "DOC", "TXT", "DOCX"];

  const [userRequest, setUserRequest] = useState({});
  const [measurements, setMeasurements] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newMeasure, setNewMeasure] = useState("");
  const [paymentTitle, setPaymentTitle] = useState("");
  const [newAmount, setAmount] = useState(0);
  const [payments, setPayments] = useState([]);
  const [fileDataArray, setFileDataArray] = useState([]);
  const [docArray, setDocArray] = useState([]);
  const [isTrending, setIsTrending] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [projectPreview, setProjectPreview] = useState(false)
  const [projectPreviewDetails, setProjectPreviewDetails] = useState(null)
  const handleAddField = () => {
    if (newTitle && newMeasure) {
      setMeasurements((prev) => [
        ...prev,
        { title: newTitle, measure: newMeasure },
      ]);
      setNewTitle("");
      setNewMeasure("");
    }
  };
  const handleClosePreview = () => {
    setProjectPreview(false)
  }
  const handleAddPayments = () => {
    if (paymentTitle && newAmount) {
      setPayments((prev) => [
        ...prev,
        { title: paymentTitle, amount: newAmount },
      ]);
      setPaymentTitle("");
      setAmount("");
    }
  };

  const handleFieldChange = (index, key, value) => {
    const updatedMeasurements = measurements.map((measurement, i) =>
      i === index ? { ...measurement, [key]: value } : measurement
    );
    setMeasurements(updatedMeasurements);
  };

  const handleDeleteField = (index) => {
    setMeasurements((prev) => prev.filter((_, i) => i !== index));
  };

  const addProjectFormik = useFormik({
    initialValues: {
      measurements: [
        {
          title: "measurement",
          measure: "",
        },
      ],
      images: [
        {
          title: "",
          url: "",
        },
      ],
      documents: [
        {
          title: "",
          url: "",
        },
      ],
      // estimationAmount: {
      //   amount: "",
      // },
      materialAmount: {
        amount: "",
      },
      platformAmount: {
        amount: "",
      },
      labourAmount: {
        amount: "",
      },
      vatAmount: {
        amount: "",
        percentage: "",
      },
      // payments: [],
      milestones: [],
      duration: "",
      type: "",
      requestId: id,
      isTrending: false,
    },
    onSubmit: async (values) => {
      console.log(milestones, "mile");
      // values.measurements = measurements;
      values.payments = payments;
      values.milestones = milestones;
      values.isTrending = isTrending;

      if (milestones.length == 0) {
        toast.error("Please add milestone");
        return;
      }

      if (fileDataArray.length > 0) {
        await Promise.all(
          fileDataArray.map(async (item, index) => {
            const formData = new FormData();
            formData.append("file", item.file);
            const res = await uploadMediaImage(formData, "service");
            if (res.status) {
              if (!addProjectFormik.values.images[index]) {
                addProjectFormik.values.images[index] = { title: "", url: "" };
              }
              addProjectFormik.values.images[index].url = res.data[0];
              addProjectFormik.values.images[index].title = item.title;
            }
          })
        );
      }

      if (docArray.length > 0) {
        await Promise.all(
          docArray.map(async (item, index) => {
            const formData = new FormData();
            formData.append("file", item.file);
            const res = await uploadMediaImage(formData, "service");
            if (res.status) {
              if (!addProjectFormik.values.documents[index]) {
                addProjectFormik.values.documents[index] = {
                  title: "",
                  url: "",
                };
              }
              addProjectFormik.values.documents[index].url = res.data[0];
              addProjectFormik.values.documents[index].title = item.title;
            }
          })
        );
      }
      const isEmpty = Object.values(values.images[0]).every(
        (value) => value !== ""
      );
      if (!isEmpty) {
        values.images = [];
      }

      const isEmptyDocuments = Object.values(values.documents[0]).every(
        (value) => value !== ""
      );
      if (!isEmptyDocuments) {
        values.documents = [];
      }

      //checking if measurement is empty
      const isEmptyMeasurement = Object.values(values.measurements[0]).every(
        (value) => value !== ""
      );
      if (!isEmptyMeasurement) {
        values.measurements = [];
      }
      console.log("data  ", JSON.stringify(values));
      setProjectPreviewDetails(values)
      setProjectPreview(true)

    },
  });

  const handleAddProject = async (payload) => {
    payload.milestones = payload.milestones.map(el => {
      delete el.milestone;
      return el
    })
    const response = await createProject(payload);
    if (response.status) {
      toast.success("Project created Successfully");
      setProjectPreviewDetails(null)
      handleClosePreview()
      navigate("/requests/approved");
    } else {
      toast.error(response.message);
    }
  }
  const getRequestDetails = async () => {
    const response = await fetchRequestDetails(id);
    if (response.status) {
      setUserRequest(response.data);
      setServiceId(response.data?.serviceDetails?._id);
    }
  };

  const handleAddPayment = () => {
    // const newSerial = payments.length + 1;
    setPayments([...payments, { title: "", amount: 0 }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedPayments = payments.map((payment, i) =>
      i === index ? { ...payment, [name]: value } : payment
    );
    setPayments(updatedPayments);
  };

  const handleDeletePayment = (index) => {
    const updatedPayments = payments.filter((_, i) => i !== index);
    setPayments(updatedPayments);
  };

  const handleAddMoreOption = () => {
    addProjectFormik.setFieldValue("images", [
      ...addProjectFormik.values.images,
      "",
    ]);
  };

  const handelDeleteOption = (index) => {
    addProjectFormik.setFieldValue(
      "images",
      addProjectFormik.values.images.filter((value, ind) => {
        return ind !== index;
      })
    );
  };

  useEffect(() => {
    getRequestDetails();
  }, []);

  const [milestones, setMilestones] = useState([]);

  const handleMilestonesChange = (updatedMilestones) => {
    setMilestones(updatedMilestones);
  };

  useEffect(() => {
    const labourAmount =
      parseFloat(addProjectFormik.values.labourAmount.amount) || 0;
    const platformAmount =
      parseFloat(addProjectFormik.values.platformAmount.amount) || 0;
    const vatPercentage =
      parseFloat(addProjectFormik.values.vatAmount.percentage) || 0;

    // Calculate VAT Amount
    const vatAmount = (labourAmount + platformAmount) * (vatPercentage / 100);

    // Update the formik value for vatAmount.amount
    addProjectFormik.setFieldValue(
      "vatAmount.amount",
      Number(vatAmount.toFixed(2))
    );
  }, [
    addProjectFormik.values.labourAmount.amount,
    addProjectFormik.values.platformAmount.amount,
    addProjectFormik.values.vatAmount.percentage,
  ]);


  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="section-heading top_heading">
              <img
                className="i_button"
                src={backBtn}
                alt="back"
                onClick={() => {
                  navigate("/requests/new");
                }}
              />
              Create Project
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box"></div>
      <div className="payout_data-content mb-5 fist_Sec">
        <div className="row mb-2">
          <div className="col-lg-4">
            <div className="data">
              Request ID: <span>{userRequest?.requestId}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div>
              <div className="data">
                Consumer Name: <span>{userRequest?.userDetails?.name}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="data">
              Service: <span>{userRequest?.serviceDetails?.name}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="data">
              Address: <span>{userRequest?.addressDetails?.address}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="data">
              Mobile: <span>{userRequest?.userDetails?.mobile}</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="data">
              Scheduled Date & Time:{" "}
              <span>
                {moment(userRequest?.schedule).format("DD MMM YYYY hh:mm a")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box"></div>

      <form
        action=""
        // className="payment_form"
        onKeyDown={handleEnterKey}
        onSubmit={addProjectFormik.handleSubmit}
      >
        <div className="section-heading mt-5">Project Details</div>

        <div className="payout_data-content mb-5 second_sec">
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label mb-2">
                  Project Type
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter project type"
                    id="beneficiary"
                    name="type"
                    onChange={(e) => {
                      addProjectFormik.setFieldValue("type", e.target.value);
                    }}
                    value={addProjectFormik.values.type ?? ""}
                    required
                  // onKeyDown={handleEnterKey}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label mb-2">
                  Work Duration
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter work duration"
                    id="beneficiary"
                    name="duration"
                    onChange={(e) => {
                      addProjectFormik.setFieldValue(
                        "duration",
                        e.target.value
                      );
                    }}
                    value={addProjectFormik.values.duration ?? ""}
                    required
                  // onKeyDown={handleEnterKey}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="input-box">
              <div className="input-label"></div>
              <div className="input-label">Is Trending?</div>
              <div className="checkform_box">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                    onChange={() => {
                      setIsTrending(true);
                    }}
                    required
                  // onKeyDown={handleEnterKey}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                    onChange={() => {
                      setIsTrending(false);
                    }}
                    required
                  // onKeyDown={handleEnterKey}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row one_row">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label mb-2">Description</div>
                <div className="input_field">
                  <textarea
                    placeholder="Title"
                    value={addProjectFormik.values.measurements[0].measure}
                    className="input"
                    onChange={(e) =>
                      addProjectFormik.setFieldValue(
                        `measurements[0].measure`,
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="input-label mt-2">Milestones</div>

            <div className="col-lg-12">
              <Milestones
                onMilestonesChange={handleMilestonesChange}
                serviceId={serviceId}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="row mb-3">
              <div className="col-lg-6 upload_field">
                <div className="input-label mb-2">Upload Images</div>

                <FileUpload
                  fileDataArray={fileDataArray}
                  setFileDataArray={setFileDataArray}
                  fileTypes={imagesTypes}
                />
              </div>
              <div className="col-lg-6 upload_field">
                <div className="input-label mb-2">Upload Documents</div>

                <FileUpload
                  fileDataArray={docArray}
                  setFileDataArray={setDocArray}
                  fileTypes={docTypes}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-heading">Budget Details</div>
        <div className="payout_data-content third_sec">
          <div className="row mb-4">
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Material Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <div className="input-group">
                    <div className="input-group-prepend dollar_sym">
                      <span className="input-group-text">{currencySymbol}</span>
                    </div>
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Enter material amount"
                      onKeyDown={(e) => handleKeyDown(e, 9)}
                      id="beneficiary"
                      min="1"
                      name="materialAmount.amount"
                      onChange={addProjectFormik.handleChange}
                      value={addProjectFormik.values.materialAmount.amount}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Labour Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <div className="input-group">
                    <div className="input-group-prepend dollar_sym">
                      <span className="input-group-text">{currencySymbol}</span>
                    </div>
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Enter labour amount"
                      onKeyDown={(e) => handleKeyDown(e, 9)}
                      id="beneficiary"
                      min="1"
                      name="labourAmount.amount"
                      onChange={addProjectFormik.handleChange}
                      value={addProjectFormik.values.labourAmount.amount}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Platform Amount
                  <span>*</span>
                </div>
                <div className="input_field">
                  <div className="input-group">
                    <div className="input-group-prepend dollar_sym">
                      <span className="input-group-text">{currencySymbol}</span>
                    </div>
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Enter platform amount"
                      id="beneficiary"
                      min="1"
                      name="platformAmount.amount"
                      onChange={addProjectFormik.handleChange}
                      value={addProjectFormik.values.platformAmount.amount}
                      required
                      onKeyDown={(e) => handleKeyDown(e, 9)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">
                  Vat Percentage
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter vat"
                    id="beneficiary"
                    min="1"
                    max="100"
                    name="vatAmount.percentage"
                    onChange={addProjectFormik.handleChange}
                    value={addProjectFormik.values.vatAmount.percentage}
                    required
                    // onKeyDown={(e) => {
                    //   calculateVatAmount(e.target.value);
                    // }}
                    onKeyDown={(e) => handleKeyDown(e, 2)}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-box">
                <div className="input-label">Vat Amount</div>
                <div className="input_field">
                  <div className="input-group">
                    <div className="input-group-prepend dollar_sym">
                      <span className="input-group-text ">{currencySymbol}</span>
                    </div>
                    <input
                      type="text"
                      className="form-control input"
                      placeholder="Enter vat amount"
                      id="beneficiary"
                      name="vatAmount.amount"
                      onChange={addProjectFormik.handleChange}
                      value={addProjectFormik.values.vatAmount.amount}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end gap-2 mt-4">
            <button
              className="btn btn-warning rounded-pill"
              type="button"
              onClick={() => {
                navigate("/requests/new");
              }}
            >
              Cancel
            </button>
            <button className="btn btn-success rounded-pill" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
      {/* </div> */}
      <Modal
        className="preview_popup"
        show={projectPreview}
        onHide={handleClosePreview}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="scroll-v">
          <Modal.Header closeButton>
            <Modal.Title>Preview Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddProjectPreview values={projectPreviewDetails} />
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_txt_div">
              <div className="footer_txt">
              Ensure all details are entered correctly for this job before submission. Once submitted, the details cannot be altered. 
              </div>
              <div className="footer_btn">
                <Button variant="secondary" onClick={handleClosePreview} className="btn_reject">
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleAddProject(projectPreviewDetails)} className="btn_accept">
                  Accept
                </Button>
              </div>
            </div>


          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};
