import React, { useState, useEffect } from "react";
import Select from "react-select";
import searchIcon from "../../assets/images/build_search.svg";
import editBtn from "../../assets/images/edit_btn.svg";
import filterImg from "../../assets/images/Up_Down.svg";
import plusWhite from "../../assets/images/plus-white.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { Backdrop } from "../backdrop";

import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import Modal from "react-bootstrap/Modal";
import { handleKeyDown } from "../../helper/helper";
import { Loader } from "../loader";
import requestList from "../../userRequests/requests.json";
import { useNavigate } from "react-router-dom";
import { fetchRequestsByStatus } from "../../action/request";
import { fetchRegions, fetchService } from "../../action/service";
import { PaginatedItems } from "../pagination";
import CommonFilter from "../../components/CommonFilter/commonFilter";
import Pagination from "../../components/customPaginantion/paginationView";

export const RejectedReqests = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [userRequest, setUserRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [searchService, setSearchService] = useState("");
  const [search, setSearch] = useState("");

  const fetchList = async (search = "", category = "") => {
    setIsLoading(true);
    const status = 2;
    const response = await fetchRequestsByStatus(
      currentPage,
      paginationValue,
      status,
      category,
      search,
      sort,
      order
    );
    if (response.status) {
      setUserRequest(response?.data?.requests);
      setTotalRecords(response?.data?.count);
      setIsLoading(false);
    } else {
    }
  };

  useEffect(() => {
    fetchList(search, searchService);
  }, [currentPage, paginationValue, sort, order]);

  return (
    <>
      {/* <div className="payout_data-content tablePage_content_header"> */}
      <CommonFilter
        showOptions={{
          Search: {
            type: "text",
            field_name: "search",
          },
          Category: {
            type: "select",
            field_name: "category",
            api_url: "/api/service/children",
            success_key_name: "services",
          },
          // Region: {
          //   type: "select",
          //   field_name: "region",
          //   api_url: "/api/region",
          //   success_key_name: "regions",
          // },
        }}
        onSubmit={(values) => {
          setSearch(values.search);
          setSearchService(values.category);
          fetchList(values.search, values.category, values.region);
        }}
      />
      {/* </div> */}
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">Rejected Requests</div>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div className="filterBox">
                  Request ID
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Consumer Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Service
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("service");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Address
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Scheduled Date
                  <img
                    src={filterImg}
                    alt="filter"
                    className="filterImg i_button"
                    onClick={() => {
                      setSort("schedule");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Remark
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
            </tr>
          </thead>

          {/* <tbody> */}
          {isLoading ? (
            <tr>
              <td colSpan="10" className="text-center">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {userRequest.length > 0 ? (
                userRequest.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td className="noWrap">{item.requestId ?? "-"}</td>
                      <td>{item?.userDetails?.name}</td>
                      <td>{item?.serviceDetails?.name}</td>
                      <td nowrap>{item?.addressDetails?.address}</td>
                      <td>
                        {moment(item?.schedule).format("DD MMM YYYY hh:mm a")}
                      </td>
                      <td>{item?.remark}</td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan="9" className="text-center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </>
          )}
          {/* </tbody> */}
        </table>{" "}
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
      </div>
    </>
  );
};
