import React, { useState, useCallback, useEffect, useRef } from "react";
import "../../styles/milestone.css";
import trash from "../../assets/images/trash-03.svg";
import { crateMilestone, fetchMilestones } from "../../action/request";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";

import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

const Milestones = ({ onMilestonesChange, serviceId }) => {

  const [rows, setRows] = useState([]);
  const [dataSave, setDataSave] = useState([]);
  const [query, setQuery] = useState('');               // User input
  const [suggestions, setSuggestions] = useState([]);    // Fetched suggestions
  const [activeIndex, setActiveIndex] = useState(-1);    // Active index for keyboard navigation
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Dropdown visibility
  const inputRef = useRef(null);
  const milestoneRef = useRef(null);
  const descRef = useRef(null);
  const addMilestoneFormik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      serviceId: serviceId,
      _id: "",
    },
    onSubmit: async (values) => {
      if (!milestoneRef.current?.value) {
        toast.error("Please add milestone name")
        return
      }
      if (!descRef.current?.value) {
        toast.error("Please add milestone description")
        return
      }
      values.name = milestoneRef.current.value;
      values.desc = descRef.current.value; 
      values.serviceId = serviceId;
      if (!values?._id) {
        delete values._id;
        const milestone = await crateMilestone(values) 
        if (milestone) {
          setDataSave((elm) => [...elm, { ...values, _id: milestone.data?._id || "" }]);
        }
      } else {
        setDataSave((elm) => [...elm, { ...values }]);

      } 
      addMilestoneFormik.resetForm()
      milestoneRef.current.value = "";
      descRef.current.value = "";
    },
  });

  const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  const arrayMove = (array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
  };

  useEffect(() => {
    if (dataSave.length) {
      const d = dataSave.map((milestone) => ({
        checklistId: milestone?._id,
        status: 0,
        milestone: milestone
      }));
      onMilestonesChange([...d])
    }

  }, [dataSave])

  const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });

  const SortableItem = SortableElement(props => <TableRow {...props} />);


  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setDataSave(oldItems => arrayMove(oldItems, oldIndex, newIndex));
  }, []);

  const RowHandler = SortableHandle(() => <svg
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 7L4 7"
      stroke="#1C274C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20 12L4 12"
      stroke="#1C274C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20 17L4 17"
      stroke="#1C274C"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>);



  const TableRow = ({ data, className, index }) => {
    return (
      <tr className={className}>
        <td style={{ width: 90 }}>
          <div className="firstElement text-center">
            <RowHandler />
          </div>
        </td>
        <td className="table_amount text-center" style={{ width: '30%' }}>
          {data.name || (
            <input
              type="text"
              value={data.name}
              onChange={(e) => handleInputChange(e, index)}
            />
          )}
        </td>
        <td className="table_amount text-center" style={{ width: '60%' }}>
          {data.desc ?? "-"}
        </td>
        <td className="table_amount" align="center" style={{ width: 90 }}>
          <img
            src={trash}
            alt="Delete"
            className="pointer del"
            onClick={() => handleDeleteMilestone(data._id)}
          />
        </td>
      </tr>
    );
  };

  const handleInputChange = (e, index) => {
    const updatedRows = [...rows];
    updatedRows[index].name = e.target.value;
    setRows(updatedRows);
  };


  const handleDeleteMilestone = (id) => {
    const updatedDataSave = dataSave.filter((item) => item._id !== id);
    setDataSave([...updatedDataSave]);
  };
  // //console.log(selectedM)

  const milestoneData = (inputValue) =>
    new Promise(async (resolve) => {
      const response = await fetchMilestones(serviceId, inputValue);
      const data = response.data.checklists;
      resolve(data)
    });

  const getSelected = (d) => {
    if (d) {
      addMilestoneFormik.setFieldValue("_id", d?._id || "")
      milestoneRef.current.value = d.name;
      descRef.current.value = d.desc;
    } else {
      addMilestoneFormik.resetForm();
    }

  }


  useEffect(() => {
    if (query.trim()) {
      milestoneData(query).then(data => {
        setSuggestions(data);  // Assume 'data' is the list from the API
        setIsDropdownVisible(true);
      });
    } else {
      setSuggestions([]);
      setIsDropdownVisible(false);
    }
  }, [query]);


  // Handle keyboard events
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex((prev) => (prev < suggestions.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      setActiveIndex((prev) => (prev > 0 ? prev - 1 : 0));
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      handleSelect(suggestions[activeIndex]);
      setQuery("")
      e.preventDefault()
    } else if (e.key === 'Escape') {
      setIsDropdownVisible(false);
    }
  };

  // Handle selection of an item
  const handleSelect = (item) => { 
    getSelected(item)
    setSuggestions([]);
    setIsDropdownVisible(false);
    setActiveIndex(-1);
  };

  // Handle outside click to close the dropdown
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleMilestoneChange = (e) => {
    setQuery(e.target.value)
    descRef.current.value = ""
    addMilestoneFormik.setFieldValue("_id", "")
  }

  return (
    <>
      <div className="scroll_y">
        <table id="table" className="milestone_tb">
          <SortableCont
            onSortEnd={onSortEnd}
            axis="y"
            lockAxis="y"
            lockToContainerEdges={true}
            lockOffset={["30%", "50%"]}
            helperClass="helperContainerClass"
            useDragHandle={true}
          >
            {dataSave.map((data, index) => (
              <SortableItem
                key={`item-${index}`}
                index={index}
                data={data}
              />
            ))}
          </SortableCont>


        </table>
      </div>
      <div className="row mb-3">
        <div className="col-lg-5">
          <div className="">
            {/* <AsyncCreatableSelect
            value={addMilestoneFormik.values?.name ? {label:addMilestoneFormik.values.name, value: addMilestoneFormik.values.name} : null}
              isClearable
              cacheOptions
              defaultOptions
              onChange={getSelected}
              loadOptions={milestoneData}
            /> */}

            <div ref={inputRef} className="search-container input_field" style={{ position: 'relative' }}>
              <input
                type="text"
                // value={query}
                ref={milestoneRef}
                onChange={handleMilestoneChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter milestone"
                className="search-input input"
              />

              {isDropdownVisible && suggestions.length > 0 && (
                <ul className="suggestions-list" style={{ position: 'absolute', top: '100%', left: 0 }}>
                  {suggestions.map((item, index) => (
                    <li
                      key={item._id}  // Assuming each item has a unique '_id'
                      className={`suggestion-item ${index === activeIndex ? 'active' : ''}`}
                      onMouseDown={() => handleSelect(item)} // Use onMouseDown to avoid losing focus on click
                      value={item._id}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="">
            <div className="input_field">
              <input
                type="text"
                className=" input"
                name="desc"
                ref={descRef}
                placeholder="Enter description"
                maxLength={60}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <button
            className="btn rounded-pill btn-success milestone-btn"
            type="button"
            onClick={addMilestoneFormik.handleSubmit}
          >
            Add New Milestone
          </button>
        </div>
      </div>
    </>
  );
};

export default Milestones;
