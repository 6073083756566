import { requestWithoutToken, requestWithToken } from "../helper/helper";

// const fetchRequestsByStatus = async (page, limit, status,category,region,search) => {
//   try {
//     const response = await requestWithToken(
//       "GET",
//       category && region ?
//       `/api/request?page=${page}&limit=${limit}&status=0&service=${category}&region=${region}` :
//       status == 0 ?
//       `/api/request?page=${page}&limit=${limit}&status=0` :
//       status
//         ? `/api/request?page=${page}&limit=${limit}&status=${status}`
//         : `/api/request?page=${page}&limit=${limit}`
//     );

//     return response?.data;
//   } catch (err) {
//     return err.response?.data;
//   }
// };

const fetchRequestsByStatus = async (
  page,
  limit,
  status,
  category,
  // region,
  search,
  sort,
  order
) => {
  try {
    // Start building the query parameters
    const queryParams = [];

    if (page) queryParams.push(`page=${page}`);
    if (limit) queryParams.push(`limit=${limit}`);
    if (status !== undefined && status !== null && status !== 9)
      queryParams.push(`status=${status}`);
    if (category) queryParams.push(`service=${category}`);
    // if (region) queryParams.push(`region=${region}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    // Make the request with the built query string
    const response = await requestWithToken(
      "GET",
      `/api/request${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchRequestDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/request/project/details/${id}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchMilestones = async (id,searchQuery= "") => {
  try {
    const response = await requestWithToken("GET", `/api/checklist?id=${id}&search=${encodeURIComponent(searchQuery)}`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const putRequestApprove = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/projects/request/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchRequestedContractorList = async (id, page, limit) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/request/contractor/request/${id}?page=${page}&limit=${limit}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const putAssignToContractor = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/admin/requests/${id}/assign`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const createProject = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/request/project`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const rejectRequest = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/admin/requests/${id}/reject`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const putContractorPayment = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/contractor/payment/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const getProjectDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/request/project/details/${id}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const putMilestoneStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/milestone/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const putConsumerPaymentStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/payment/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const contractorPaymentAdd = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/contractor/payment/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const addConsumerPayment = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/consumer/payment/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const crateMilestone = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/api/checklist`, payload);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const updateProject = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/request/projects/request/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

export {
  fetchRequestsByStatus,
  fetchRequestDetails,
  fetchMilestones,
  putRequestApprove,
  fetchRequestedContractorList,
  putAssignToContractor,
  createProject,
  rejectRequest,
  putContractorPayment,
  getProjectDetails,
  putMilestoneStatus,
  putConsumerPaymentStatus,
  contractorPaymentAdd,
  crateMilestone,
  addConsumerPayment,
  updateProject,
};
