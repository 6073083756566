import infoIcon from "../../assets/images/eye.svg";
import { currencySymbol } from "../../helper/constants";
import { formatLongNumberValue } from "../../helper/helper";
export default function AddProjectPreview({ values }) {
    return (<div className="preview-body">
        <div className="payout_data-content fist_Sec">
            <h6>Project details:</h6>
            <div className="row mb-2">
                <div className="col-lg-4">
                    <div className="data">
                        Project Type: <span>{values?.type}</span>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div>
                        <div className="data">
                            Work Duration: <span>{values?.duration}</span>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="data">
                        Is Trending?: <span>{values?.isTrending ? "Yes" : "No"}</span>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="data">
                        Project Description: <span>{values?.measurements?.[0]?.measure || ""}</span>
                    </div>
                </div>


            </div>
            <h6 className="mt-3">Milestone details:</h6>
            <table>
                <tr><th style={{ width: "35%" }}>Milestone</th><th style={{ width: "65%" }}>Description</th></tr>
                {values?.milestones?.map(el => (<tr key={`milestone-${el.checklistId}`}><td>{el.milestone.name}</td><td>{el.milestone.desc}</td></tr>))}
            </table>
            <h6 className="mt-3">Images:</h6>
            <table>
                <tr><th style={{ width: "35%" }}>Title</th><th style={{ width: "65%" }}>View Url</th></tr>
                {values?.images?.map((el, i) => (<tr key={`image-${i}`}><td>{el.title}</td><td><a href={process.env.REACT_APP_S3_URL + "/" + el.url} target="_blank" rel="noopener noreferrer">
                    <img src={infoIcon} className="fitimg" />
                </a></td></tr>))}
            </table>
            <h6 className="mt-3">Documents:</h6>
            <table>
                <tr><th style={{ width: "35%" }}>Title</th><th style={{ width: "65%" }}>View Url</th></tr>
                {values?.documents?.map((el, i) => (<tr key={`docs-${i}`}><td>{el.title}</td><td><a href={process.env.REACT_APP_S3_URL + "/" + el.url} target="_blank" rel="noopener noreferrer">
                    <img src={infoIcon} className="fitimg" />
                </a></td></tr>))}
            </table>
            <h6 className="mt-3">Budget Details:</h6>
            <div className="row mb-2">
                <div className="col-lg-4">
                    <div className="data">
                        Material Amount: <span>{currencySymbol+" "}{formatLongNumberValue(values?.materialAmount?.amount)}</span>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div>
                        <div className="data">
                            Labour Amount: <span>{currencySymbol+" "}{formatLongNumberValue(values?.labourAmount?.amount)}</span>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="data">
                        Platform Amount: <span>{currencySymbol+" "}{formatLongNumberValue(values?.platformAmount?.amount)}</span>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="data">
                        Vat Percentage: <span>{values?.vatAmount?.percentage} %</span>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="data">
                        Vat Amount: <span>{currencySymbol+" "}{formatLongNumberValue(values?.vatAmount?.amount)}</span>
                    </div>
                </div>

            </div>
        </div>
    </div>)
}