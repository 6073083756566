export const menu = [
  {
    title: "Dashboard",
    icon: "home",
    path: "/",
  },
  {
    title: "Requests",
    path: "",
    icon: "requests",
    children: [
      {
        title: "New",
        icon: "newReq",
        path: "/requests/new",
      },
      {
        title: "Approved",
        icon: "approve",
        path: "/requests/approved",
      },
      {
        title: "Rejected",
        icon: "reject",
        path: "/requests/rejected",
      },
      {
        title: "Completed",
        icon: "completed",
        path: "/requests/completed",
      },
    ],
  },
  {
    title: "Contractors",
    path: "/contractors",
    icon: "contractor",
  },
  {
    title: "Consumers",
    icon: "consumer",
    path: "/consumers",
  },
  {
    title: "Feedbacks",
    icon: "feedback",
    path: "/feedbacks",
  },
  {
    title: "Queries",
    path: "",
    icon: "query",
    children: [
      {
        title: "Contact Us",
        icon: "serviceQuery",
        path: "/queries/contactus",
      },
      {
        title: "Ask Us",
        icon: "askus",
        path: "/queries/askus",
      },
      {
        title: "Service Query",
        icon: "contactus",
        path: "/queries/service/query",
      },
    ],
  },
  {
    title: "Services",
    icon: "feedback",
    path: "/services",
  },
];
