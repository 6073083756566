import { requestWithToken } from "../helper/helper";

const fetchService = async (pageNo, pageSize, search, sort, order, id) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`page=${pageNo}`);
    if (pageSize) queryParams.push(`limit=${pageSize}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (id) queryParams.push(`id=${id}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/api/service${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchRegions = async () => {
  try {
    const response = await requestWithToken("GET", `/api/region`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchTowns = async () => {
  try {
    const response = await requestWithToken("GET", `/api/town`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchVillages = async () => {
  try {
    const response = await requestWithToken("GET", `/api/village`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchParentServices = async (pageNo, pageSize, search, sort, order) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`page=${pageNo}`);
    if (pageSize) queryParams.push(`limit=${pageSize}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/api/service/parents${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchChildServices = async (
  pageNo,
  pageSize,
  id,
  search,
  sort,
  order
) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`page=${pageNo}`);
    if (pageSize) queryParams.push(`limit=${pageSize}`);
    if (id) queryParams.push(`id=${id}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/api/service/children${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const createService = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/api/service`, payload);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const deleteService = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/api/service/${id}`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const getServiceDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/api/servicedetails/${id}`);

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const editService = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/service/${id}`,
      payload
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

export {
  fetchService,
  fetchRegions,
  fetchTowns,
  fetchVillages,
  fetchParentServices,
  fetchChildServices,
  createService,
  deleteService,
  getServiceDetails,
  editService,
};
