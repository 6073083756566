import { requestWithToken, requestWithoutToken } from "../helper/helper";
import { useQuery } from "@tanstack/react-query";

const fetchRoles = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/roles`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchUsers = async (page, limit, slug, search, sort, order) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/user?page=${page}&limit=${limit}&slug=${slug}&search=${search}&sort=${sort}&order=${order}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getUserRequests = async (pageNo, pageSize, serviceId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/userRequests?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const uploadMediaImage = async (formData, prefix) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/upload/file/${prefix}`,
      formData
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getMe = async () => {
  try {
    const response = await requestWithToken("GET", `/api/user/me`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getUserDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/api/user/details/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createUser = async (payload) => {
  try {
    const response = await requestWithoutToken("POST", `/api/user`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createContractor = async (payload) => {
  try {
    const response = await requestWithoutToken(
      "POST",
      `/api/user/contractor`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createBusiness = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/business-details`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getFeedbacks = async (pageNo, pageSize, status, search, sort, order) => {
  try {
    // Start building the query parameters
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (status) queryParams.push(`status=${status}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);

    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/api/feedback${queryString}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const updateFeedback = async (payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/api/feedback/edit/status`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const sendVerificaionOTP = async (payload) => {
  try {
    const response = await requestWithoutToken(
      "POST",
      `/api/user/verificationOtp`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const verifyOTP = async (id, payload) => {
  try {
    const response = await requestWithoutToken(
      "PUT",
      `/api/user/otpVerify/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  fetchRoles,
  fetchUsers,
  getUserRequests,
  uploadMediaImage,
  getMe,
  getUserDetails,
  createUser,
  createBusiness,
  createContractor,
  getFeedbacks,
  updateFeedback,
  sendVerificaionOTP,
  verifyOTP,
};
