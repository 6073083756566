import React, { useState, useEffect } from "react";
import filterImg from "../../assets/images/Up_Down.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";
import { fetchRequestsByStatus, rejectRequest } from "../../action/request";
import { fetchRegions } from "../../action/service";
import CommonFilter from "../../components/CommonFilter/commonFilter";
import Pagination from "../../components/customPaginantion/paginationView";

export const UserReqests = () => {
  const navigate = useNavigate();
  //   const [date, setDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(10);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [id, setId] = useState("");
  const [userRequest, setUserRequest] = useState([]);
  const [services, setServices] = useState([]);
  const [isLoad, setIsLoading] = useState(false);
  const [searchService, setSearchService] = useState("");
  const [search, setSearch] = useState("");
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");

  const handlePaginationChange = (event) => {
    setCurrentPage(event);
  };

  const fetchList = async (search = "", category = "") => {
    setIsLoading(true);
    const status = 0;
    const response = await fetchRequestsByStatus(
      currentPage,
      paginationValue,
      status,
      category,
      // region,
      search,
      sort,
      order
    );
    if (response.status) {
      setUserRequest(response.data.requests);
      setTotalRecords(response?.data?.count);
      setIsLoading(false);
    } else {
    }
  };

  // const getServices = async () => {
  //   const response = await fetchService();
  //   if (response.status) {
  //     setServices(response?.data?.services);
  //   }
  // };

  const rejectProject = async () => {
    if (remark.trim() === "") {
      setError("Please enter remark");
    } else {
      const payload = {
        remark: remark,
      };
      const response = await rejectRequest(id, payload);
      if (response.status) {
        setIsOpenRejectModal(false);
        fetchList();
        toast.success(response.message, {
          autoClose: 2000, // 2000 milliseconds = 2 seconds
        });
      } else {
        toast.error(response.message);
      }
    }
  };

  useEffect(() => {
    fetchList(search, searchService);
  }, [currentPage, paginationValue, sort, order]);

  const handleRedirect = () => {
    // console.log("accepted");
    setIsOpenModal(false);
    navigate(`/requests/new/add/${id}`);
  };

  return (
    <div class="payout_inner_content">
      <CommonFilter
        showOptions={{
          Search: {
            type: "text",
            field_name: "search",
          },
          Category: {
            type: "select",
            field_name: "category",
            api_url: "/api/service/children",
            success_key_name: "data",
          },
          // Region: {
          //   type: "select",
          //   field_name: "region",
          //   api_url: "/api/region",
          //   success_key_name: "regions",
          // },
        }}
        onSubmit={(values) => {
          setSearch(values.search);
          setSearchService(values.category);
          fetchList(values.search, values.category);
        }}
      />
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl1">New Requests</div>
          </div>
        </div>
      </div>
      <div className="customtable_box">
        <table className="customtable">
          <thead>
            <tr>
              <th>
                <div className="filterBox">
                  Request ID
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Consumer Name
                  <img
                    src={filterImg}
                    alt="filter"
                    className="i_button filterImg"
                    onClick={() => {
                      setSort("name");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Service
                  <img
                    src={filterImg}
                    alt="filter"
                    className="i_button filterImg"
                    onClick={() => {
                      setSort("service");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Address
                  {/* <img src={filterImg} alt="filter" className="filterImg" /> */}
                </div>
              </th>
              <th>
                <div className="filterBox">
                  Scheduled Date
                  <img
                    src={filterImg}
                    alt="filter"
                    className="i_button filterImg"
                    onClick={() => {
                      setSort("schedule");
                      setOrder(order == Number(1) ? Number(-1) : Number(1));
                    }}
                  />
                </div>
              </th>
              <th>
                <div className="text-center">Action</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {isLoad ? (
              <tr>
                <td colSpan="10" className="">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {userRequest.length > 0 ? (
                  userRequest.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item?.requestId}</td>
                        <td>{item?.userDetails?.name}</td>
                        <td>{item?.serviceDetails?.name}</td>
                        <td className="ellipsis">
                          {item?.addressDetails?.address}
                        </td>
                        <td>
                          {moment(item?.schedule).format("DD MMM YYYY hh:mm a")}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center gap-2">
                            <button
                              type="button"
                              class="btn_accept btn_submit"
                              onClick={() => {
                                setId(item?._id);
                                setIsOpenModal(true);
                              }}
                            >
                              Accept
                            </button>

                            <button
                              type="button"
                              class="btn btn_reject btn_submit"
                              onClick={() => {
                                setId(item?._id);
                                setIsOpenRejectModal(true);
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
        {/* <PaginatedItems
          itemsPerPage={Math.ceil(Number(totalRecords) / 10) || 0}
          onclick={handlePaginationChange}
          currentPage={paginationValue}
        /> */}
        {/* Add milestone model */}
        <Modal
          show={isOpenModal}
          onHide={() => {
            setIsOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            By accepting, you agree to create a milestone and the budget. Would
            you like to proceed?
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-secondary no-btn"
              data-bs-dismiss="modal"
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-primary yes-btn"
              onClick={handleRedirect}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
        {/* CANCEL Request model */}
        <Modal
          show={isOpenRejectModal}
          onHide={() => {
            setIsOpenRejectModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure want to reject this request?
            <div className="input-box">
              <div className="input-label">
                Remark {"  "}
                <span>*</span>
                {error && <span className="error-message">{error}</span>}
              </div>
              <div className="input_field">
                <input
                  type="text"
                  className="input"
                  placeholder="Enter remark"
                  id="beneficiary"
                  name="remark"
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  required={true}
                  maxLength={200}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-secondary yes-btn"
              data-bs-dismiss="modal"
              onClick={() => {
                rejectProject();
              }}
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-primary no-btn"
              onClick={() => {
                setIsOpenRejectModal(false);
              }}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
